import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { v4 as uuid } from 'uuid';

import { City } from 'app/typings/cities';
import { useCurrentTheme } from 'app/shared/theme';
import { useAnalyticsContext } from 'app/shared/utils';
import { useCitiesSearchOnInput } from 'app/shared/utils/useCitiesSearchOnInput';
import { GetClosestCitiesLazy } from 'app/shared/graphql/cities/queryHooks';
import { GetCitiesForCitySelectorLazy } from 'app/shared/graphql/cities/queryHooks';
import { AuthContext } from 'app/shared/context/Auth';
import { useCityContext } from 'app/shared/context/City';
import { ListItem } from 'app/shared/components/atoms';
import { Typography } from 'app/shared/components/atoms';
import { Select } from 'app/shared/components/molecules';
import { ActionButtonColors } from 'app/shared/components/molecules/SelectManualCSS/Input';

const defaultOptionClick = (option: City) => {
  if (option) {
    window.location.replace(`/cities/${option.cachedSlug}`);
  }
};

interface Props {
  onChange?: (city?: City) => void;
  onBlur?: () => void;
  searchString?: string;
  setSearchString?: (value?: string) => void;
  backgroundColor?: string;
  color?: string;
  trackingKey?: string;
  'data-qaid'?: string;
  disableTopCities?: boolean;
  disableClosestCities?: boolean;
  recommendedOptionsTitle?: string;
  recommendedOptionsTitleSingular?: string;
  mostVisitedOptionsTitle?: string;
  cityOperatingModels?: string[];
  orderBy?: string;
  orderDirection?: string;
  cityIds?: number[];
  disabled?: boolean;
  value?: City;
  parentContainer?: string;
  showInactiveCities?: boolean;
  invertColors?: boolean;
  actionButtonColors?: ActionButtonColors;
  initialWidth?: string;
  name?: string;
  errors?: any;
  initialValue?: City;
  placeholder?: string;
  className?: string;
  groupBy?: (option: City) => string;
  showCityOperatingModel?: boolean;
  keepInViewPort?: boolean;
  showCountry?: boolean;
  hideInputLabel?: boolean;
  placeholderColor?: string;
  displayFixedLabel?: boolean;
  label?: string;
  labelColor?: string;
}

function filterCitiesBy(city: City, query?: string) {
  if (!query) {
    return true;
  }

  const q = query.trim().toLowerCase();
  const optTitle = city.title.toLowerCase();
  const aliases = (city.aliases || '').toLowerCase();
  return optTitle.indexOf(q) > -1 || aliases.indexOf(q) > -1;
}

const CitySelector = ({
  searchString,
  setSearchString,
  onChange,
  errors,
  backgroundColor,
  placeholderColor,
  color,
  invertColors,
  trackingKey,
  recommendedOptionsTitle,
  recommendedOptionsTitleSingular,
  mostVisitedOptionsTitle,
  disableTopCities,
  disableClosestCities,
  cityOperatingModels,
  showInactiveCities,
  disabled = false,
  orderBy = 'title',
  orderDirection = 'asc',
  cityIds,
  parentContainer,
  value,
  'data-qaid': qaId = 'city-selector',
  initialValue,
  placeholder,
  initialWidth,
  showCountry,
  showCityOperatingModel = false,
  name = 'city',
  hideInputLabel,
  label,
  labelColor,
}: Props) => {
  const theme = useCurrentTheme();
  const intl = useIntl();
  const [citySearchSessionID, setCitySearchSessionID] = useState<string>(); // string to uniquely id a single flow of searching for a city for tracking
  const [citySearchInput, setCitySearchInput] = useState('');
  const [
    analyticsBringToSofarShownTracked,
    setAnalyticsBringToSofarShownTracked,
  ] = useState(false);
  const { trackAnalyticsEvent } = useAnalyticsContext();
  const { visa } = useContext(AuthContext);
  const cityContextValue = useCityContext();
  const [loadTopCities, topCitiesResponse] = GetCitiesForCitySelectorLazy({});
  const [loadClosestCities, closestCitiesResponse] = GetClosestCitiesLazy({});

  const [
    loadOperatingModelCities,
    operatingModelCitiesResponse,
  ] = GetCitiesForCitySelectorLazy({});

  const citiesResponse = useCitiesSearchOnInput({
    citySearchSessionID,
    citySearchInput,
    showAllCitiesOnMount: disableTopCities && disableClosestCities,
    showInactiveCities,
    filterBy: filterCitiesBy,
    orderBy,
    orderDirection,
  });

  useEffect(() => {
    if (!disableTopCities) {
      loadTopCities({ variables: { topGlobalCity: true } });
    }
  }, [disableTopCities, loadTopCities]);

  useEffect(() => {
    if (
      !disableClosestCities &&
      cityContextValue &&
      !cityContextValue.closestCities
    ) {
      loadClosestCities();
    }
  }, [disableClosestCities, cityContextValue, loadClosestCities]);

  useEffect(() => {
    if (value && citySearchInput.length === 0) {
      setCitySearchInput(value.title);
    }
  }, [value, citySearchInput]);

  const isVisaValidated = !!visa?.eligible;

  const options = cityOperatingModels
    ? getData(operatingModelCitiesResponse, cityIds)
    : getData(citiesResponse, cityIds);

  useEffect(() => {
    if (
      !analyticsBringToSofarShownTracked &&
      options.length === 0 &&
      !!citySearchInput
    ) {
      setAnalyticsBringToSofarShownTracked(true);
      trackAnalyticsEvent('City Dropdown Bring Sofar to City Shown', {
        city_search_session_id: citySearchSessionID,
        dropdown_context: parentContainer,
        city_search_input: citySearchInput,
        is_visa_validated: isVisaValidated,
      });
    }
  }, [
    options,
    citySearchInput,
    analyticsBringToSofarShownTracked,
    trackAnalyticsEvent,
    citySearchSessionID,
    parentContainer,
    isVisaValidated,
  ]);

  useEffect(() => {
    if (
      cityContextValue &&
      closestCitiesResponse &&
      !cityContextValue.closestCities
    ) {
      if (closestCitiesResponse?.data?.closestCities?.cities) {
        cityContextValue.setClosestCities(
          closestCitiesResponse?.data?.closestCities?.cities
        );
      }
    }
  }, [cityContextValue, closestCitiesResponse]);

  useEffect(() => {
    if (cityOperatingModels) {
      loadOperatingModelCities({
        variables: {
          cityOperatingModel: cityOperatingModels.join(','),
          orderBy,
          orderDirection,
        },
      });
    }
  }, [cityOperatingModels, loadOperatingModelCities, orderBy, orderDirection]);

  if (!citiesResponse) {
    return null;
  }

  function handleOpen() {
    const newCitySearchSessionID = uuid(); //TODO: Update to crypto.randomUUID() once Safari supports it: https://developer.mozilla.org/en-US/docs/Web/API/Crypto/randomUUID
    trackAnalyticsEvent('City Selector Opened', {
      city_search_session_id: newCitySearchSessionID,
      dropdown_context: parentContainer,
      is_visa_validated: isVisaValidated,
    });
    setCitySearchSessionID(newCitySearchSessionID);
  }

  function onChangeHandler(_optionValue: string | number, option: City) {
    const handler = onChange || defaultOptionClick;
    if (setSearchString) {
      setSearchString(undefined);
    }
    trackAnalyticsEvent('City Dropdown Option Selected', {
      city_search_session_id: citySearchSessionID,
      form_context: trackingKey,
      selected_city_slug: option.cachedSlug,
      city_search_input: searchString,
      dropdown_context: parentContainer,
      search_string: value,
      is_visa_validated: isVisaValidated,
    });

    handler(option);
  }

  const helperText = errors ? String(errors) : undefined;

  const noOptionsOption = {
    label: intl.formatMessage(
      {
        id: 'citySelector.noMatchingResults',
      },
      {
        citySearchInput,
      }
    ),
    onClick: () => {
      trackAnalyticsEvent('City Dropdown Bring Sofar to City Selected', {
        city_search_session_id: citySearchSessionID,
        dropdown_context: parentContainer,
        city_search_input: citySearchInput,
        is_visa_validated: isVisaValidated,
      });
      window.location.replace('/about/curators');
    },
  };

  function onSearch(searchString: string) {
    if (setSearchString) {
      setSearchString(searchString);
    }
    setCitySearchInput(searchString);
    trackAnalyticsEvent('City Dropdown Option Searched', {
      form_context: trackingKey,
      city_search_input: searchString,
    });
  }

  function getRecommendedOptionsTitle(options: City[]) {
    if (options.length === 1) {
      return recommendedOptionsTitleSingular
        ? recommendedOptionsTitleSingular
        : intl.formatMessage({ id: 'select.recommendedOptionsTitleSingular' });
    }
    return recommendedOptionsTitle
      ? recommendedOptionsTitle
      : intl.formatMessage({ id: 'select.recommendedOptionsTitle' });
  }

  function getData(response: any, cityIds?: number[]) {
    const cities =
      response && response.data
        ? response.data.cities?.cities || response.data.cities
        : [];
    if (cityIds && cityIds.length > 0) {
      return cities.filter((city: any) => cityIds.includes(city.id));
    }
    return cities;
  }

  return (
    <Select
      data-qaid={qaId}
      placeholderColor={placeholderColor}
      backgroundColor={backgroundColor}
      color={color}
      invertColors={invertColors}
      handleOptionClick={onChangeHandler}
      hasDataError={!!citiesResponse.error}
      hasValidationError={!!helperText}
      helperText={helperText}
      isLoading={citiesResponse.loading}
      isSuccessful={!!value}
      label={
        hideInputLabel
          ? null
          : label || intl.formatMessage({ id: 'shared.homeCity' })
      }
      labelColor={labelColor}
      noOptionsOption={noOptionsOption}
      isSearchable={true}
      onSearch={onSearch}
      filterBy={filterCitiesBy}
      getOptionLabel={(city: City) => `${city.title}, ${city.country.title}`}
      options={disabled ? [] : options}
      value={searchString}
      onOpen={handleOpen}
      recommendedOptions={
        cityContextValue && !disableClosestCities
          ? cityContextValue.closestCities
          : undefined
      }
      getRecommendedOptionsTitle={getRecommendedOptionsTitle}
      mostVisitedOptions={
        disableTopCities ? undefined : getData(topCitiesResponse)
      }
      getMostVisitedOptionsTitle={
        mostVisitedOptionsTitle
          ? () => mostVisitedOptionsTitle
          : () => intl.formatMessage({ id: 'select.mostVisitedOptionsTitle' })
      }
      defaultValue={initialValue}
      name={name}
      placeholder={
        placeholder || intl.formatMessage({ id: 'shared.selectACity' })
      }
      initialWidth={initialWidth}
      renderOption={(o: City, props: any) => (
        <ListItem
          key={o.cachedSlug}
          {...props}
          data-qaid={`${qaId}-option`}
          onClick={() => onChangeHandler(o.id, o)}
          invertColors={invertColors}
        >
          <Typography as="span">{o.title}</Typography>
          {showCountry && (
            <Typography
              as="span"
              color={
                invertColors
                  ? theme.colors.primary.black.light
                  : theme.colors.primary.white.base
              }
            >
              , {o.country.title}
            </Typography>
          )}
          {showCityOperatingModel && (
            <Typography
              as="span"
              color={
                invertColors
                  ? theme.colors.primary.black.light
                  : theme.colors.primary.white.base
              }
            >
              {` (${o.cityOperatingModelDescription})`}
            </Typography>
          )}
        </ListItem>
      )}
      iconColor={theme.colors.accent.primaryAccent.base}
    />
  );
};

export default CitySelector;

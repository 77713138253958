import React, { useState } from 'react';
import { Field, Formik, FormikProps } from 'formik';
import { useIntl } from 'react-intl';

import { City, OnSignUpSuccess } from 'app/typings';
import { useTrackAnalyticsOnLoad } from 'app/shared/utils';
import { TOP_GLOBAL_COUNTRY_CODES } from 'app/shared/utils/countries';
import { useAccountSignUpForm } from 'app/shared/utils/useAccountSignUpForm';
import {
  useAuthFormContext,
  useMultiStepSignUpContext,
} from 'app/shared/context';
import { withLocaleMessages } from 'app/shared/hoc';
import { Box, Typography } from 'app/shared/components/atoms';
import {
  MobilePhoneField,
  PasswordField,
  Textfield,
} from 'app/shared/components/molecules';
import {
  ModalButtonsSection,
  ModalTitle,
} from 'app/shared/components/molecules';
import { CitySelector } from 'app/shared/components/organisms';

import { ClaimAccountModal } from './ClaimAccountModal';
import { SignUpFormSchema } from './SignUpFormSchema';

interface CompleteAccountProps {
  onSuccess: OnSignUpSuccess;
  onCancel?: () => void;
  onError?: () => void;
  userEmail?: string;
  userFirstName?: string;
  userLastName?: string;
  city?: City;
  formContext: string;
  'data-qaid': string;
}

export const CompleteAccountForm: React.FC<CompleteAccountProps> = ({
  onSuccess,
  onCancel,
  onError,
  userEmail,
  city,
  formContext,
  'data-qaid': qaId,
}) => {
  const { formatMessage } = useIntl();
  useMultiStepSignUpContext(); // Raise error if not a descendent of MultiStepSignUpContextProvider
  const { isCompleteSignUpFlow, apiError } = useAuthFormContext();
  const [isClaimAccountModalOpen, setIsClaimAccountModalOpen] = useState(false);
  const [unclaimedAccountEmail, setUnclaimedAccountEmail] = useState('');

  const { onSignUpFormSubmit, initialValues } = useAccountSignUpForm({
    onSuccess,
    onError,
    userEmail,
    city,
    toggleClaimAccountModal: () => setIsClaimAccountModalOpen(true),
    setUnclaimedAccountEmail,
  });

  useTrackAnalyticsOnLoad(
    'Account Signup Form displayed',
    {
      form_context: formContext,
    },
    isCompleteSignUpFlow
  );

  const [citySearchInput, setCitySearchInput] = useState<string | undefined>(
    city?.title
  );

  if (!isCompleteSignUpFlow) {
    return null;
  }

  return (
    <Box data-qaid={qaId} flexDirection="column" gap={8}>
      <ClaimAccountModal
        isOpen={isClaimAccountModalOpen}
        setIsOpen={setIsClaimAccountModalOpen}
        accountEmailAddress={unclaimedAccountEmail}
      />
      <Box flexDirection="column" gap={4}>
        <ModalTitle
          title={formatMessage({ id: 'authentication.completeAccount.title' })}
        />
        <Typography>
          {formatMessage({ id: 'authentication.completeAccount.subtitle' })}
        </Typography>
      </Box>
      <Formik
        initialValues={initialValues}
        validationSchema={SignUpFormSchema({
          formatMessage,
        })}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          onSignUpFormSubmit({ values, setSubmitting });
        }}
      >
        {(formikProps: FormikProps<any>) => {
          const {
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
          } = formikProps;

          return (
            <form data-qaid="auth-signup-form" onSubmit={handleSubmit}>
              <Box flexDirection="column" gap={8}>
                <Field
                  readonly
                  as={Textfield}
                  data-qaid={`${qaId}-email`}
                  label={formatMessage({ id: 'shared.emailAddress' })}
                  name="email"
                  type="email"
                  autoCapitalize="off"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={
                    errors.email && touched.email && String(errors.email)
                  }
                  hasError={!!errors.email && touched.email}
                  touched={!!touched.email}
                  isRequired={true}
                />
                <Field
                  autoComplete="one-time-code"
                  as={PasswordField}
                  data-qaid={`${qaId}-password`}
                  label={formatMessage({ id: 'form.fields.password' })}
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={
                    errors.password &&
                    touched.password &&
                    String(errors.password)
                  }
                  hasError={!!errors.password && touched.password}
                  touched={!!touched.password}
                  isRequired={true}
                />
                <CitySelector
                  data-qaid={`${qaId}-city-selector`}
                  searchString={citySearchInput}
                  setSearchString={setCitySearchInput}
                  trackingKey={qaId}
                  value={city}
                  errors={touched.city ? errors.city : undefined}
                  disableClosestCities={true}
                  disableTopCities={true}
                  onChange={(city?: City) => {
                    setFieldValue('city', city);
                  }}
                />
                <Typography>
                  {formatMessage({ id: 'shared.smsSignUp.description' })}
                </Typography>
                <MobilePhoneField
                  data-qaid="mobile-phone"
                  countryCodes={TOP_GLOBAL_COUNTRY_CODES}
                  selectedCountryCode={city?.country.countryCode}
                  label="Mobile number"
                  apiError={apiError}
                  {...formikProps}
                />
                <ModalButtonsSection
                  primaryCtaDataQaid="signup-form-submit"
                  primaryCtaText={formatMessage({
                    id: 'authentication.completeAccount.submit',
                  })}
                  isPrimaryCtaLoading={isSubmitting}
                  primaryCtaType="submit"
                  secondaryCtaText={formatMessage({ id: 'shared.skipForNow' })}
                  onClickSecondaryCta={onCancel}
                  mobileFlexDirection="column"
                />
              </Box>
            </form>
          );
        }}
      </Formik>
    </Box>
  );
};
export default withLocaleMessages(CompleteAccountForm, 'fan');
